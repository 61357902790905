<template>
	<List></List>
</template>

<script>
import List from "@/components/shared/tools/wizardMindmap/List.vue";

export default {
	name: "MentorWizardMindmapListPage",

	components: {
		List,
	},
};
</script>

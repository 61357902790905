<template>
	<v-card flat class="pa-1 wr_grey_1">
		<NoData
			v-if="!list || !list.length"
			heading="app.no_maps_yet"
			@init="dialog = true"
		></NoData>

		<div v-else>
			<AppListInBoxTheme
				:list="list"
				img="step"
				id_type="uuid"
				@explore="explore"
				@delete="deleteMap"
			></AppListInBoxTheme>

			<v-btn
				absolute
				bottom
				right
				large
				fab
				class="mb-10"
				color="success"
				@click="dialog = true"
			>
				<v-icon>$vuetify.icons.values.plus</v-icon>
			</v-btn>
		</div>

		<MapNameDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@name-created="create"
		></MapNameDialog>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NoData from "@/components/shared/NoData.vue";
import AppListInBoxTheme from "@/components/ui/AppListInBoxTheme.vue";
import MapNameDialog from "@/components/shared/MindmapNameDialog.vue";
import { toolsWizardMapDeletionMixin } from "@/mixins/tools/wizardMindmap/collaborationMixin";
import { v4 as uuidv4 } from "uuid";

export default {
	name: "WizardMindmapList",

	mixins: [toolsWizardMapDeletionMixin],

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		NoData,
		AppListInBoxTheme,
		MapNameDialog,
	},

	mounted() {
		this.getList();
	},

	computed: {
		...mapState({
			list: (state) => state.wizardMindmap.list,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},

	methods: {
		async getList() {
			try {
				this.$loader.start();

				await this.$store.dispatch("wizardMindmap/list", {
					type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
					category: this.$defines.TOOLS_CATEGORY,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async create(map_name, cb) {
			try {
				await this.$store.dispatch("wizardMindmap/create", {
					category: this.$defines.TOOLS_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
					uuid: uuidv4(),
					name: map_name,
				});

				cb();

				this.dialog = false;
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},

		explore(map_id) {
			try {
				this.$router.push({
					name: this.isMentor
						? "MentorWizardMindmapExplore"
						: "WizardMindmapExplore",
					params: {
						id: map_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteMap(map_id) {
			try {
				this.$loader.start();

				await this.executeDeletion(map_id);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
